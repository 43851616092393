import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default function (uid, lang) {
  const {
    cart_items,
    discount
  } = this;

  if (!cart_items) return false;

  const parsedData = [];

  if (cart_items['joker']) parsedData.push(...cart_items['joker']);
  if (cart_items[uid]) parsedData.push(...cart_items[uid]);

  const authenticity_token = $('[name="csrf-token"]')[0].content;
  const data = {
    shopping_cart: {
      cart_items: parsedData,
      promotional_code: discount.id ? discount.id : {}
    },
    authenticity_token
  };
  const query = AxiosRequest(`/customers/${uid}/shopping_carts/checkout`, 'post', {
    ...data
  });
  query.then(({
    data
  }) => {
    const ls = new SecureLS();
    ls.remove('cart');
    window.location.replace(data.url);
  })
}
