import AxiosRequest from "../../utils/axios-request"

export default ->
  request_swal = Swal.fire {
    confirmButtonText: 'Aplicar'
    cancelButtonText: 'Cancelar'
    showCancelButton: true
    allowOutsideClick: false
    title: 'Ingrese el código promocional'
    input: 'text'
    validationMessage: 'Ingresa un correo válido'
    inputAttributes:
      autocapitalize: 'off'
    customClass:
      confirmButton: 'uk-button uk-button-primary uk-margin-right'
      cancelButton: 'uk-button uk-button-danger'
    buttonsStyling: false
  }
  request_swal.then ({value}) =>
    if value?
      AxiosRequest('/promotional_code', 'post', {code: value, total_worth: @total_sumary}, 'post').then ({data}) =>
        Swal.fire(data.message)
        @discount = data.discount
