import SecureLS from 'secure-ls'
import AxiosRequest from "../../utils/axios-request"

locale =
  es:
    confirm:
      title: '¿Está seguro?'
      text: 'Tendrá que volver a asignar la fecha y el número de personas'
    success:
      title: 'Eliminado'
      text: 'El tour ha sido removido de la compra'
  en:
    confirm:
      title: 'Are you sure?'
      text: 'You won\'t be able to revert this!'
    success:
      title: 'Removed!'
      text: 'Your tour has been removed.'

export default (index, lang, uid = 'joker', item)  ->
  { confirm, success } = if lang is 'es' then locale.es else locale.en

  #Sweet alert nivel 1
  the_sweet_alert = Swal.fire {
    title: confirm.title
    text: confirm.text
    icon: 'warning'
    showCancelButton: true
  }

  #Gestion de sweet alert
  the_sweet_alert.then (result) =>
    if result.value
      ls = new SecureLS()
      remove_item = (message) =>
        Swal.fire success.title, message, 'success'
        actual_cart[uid].splice index, 1
        @cart_items = actual_cart
        ls.set 'cart', actual_cart

      actual_cart = @cart_items

      # Borrar el item del carrito en backend cuando no es joker
      if uid isnt 'joker' and item.backened
        query = AxiosRequest '/customers/shopping_carts/remove', 'post', {
          date: item.date, persons: item.bookings, tour_date_id: item.tour_date_id
        }
        query.then ({ data }) => remove_item(data.message)
      else
        remove_item(success.text)
