import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Moment from 'moment'
import _ from 'lodash'

import SecureLS from 'secure-ls'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'
import localizedDate from '../filters/localized_date'
# METHODS
import removeItem from '../methods/show_cart/remove_item'
import checkout from '../methods/show_cart/checkout'
import promotionalCode from '../methods/show_cart/promotional_code'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_cart'
  return false unless el?

  new Vue {
    el
    filters: {toCurrency, localizedDate}
    methods: {
      removeItem,
      checkout,
      promotionalCode
    }
    data: -> {
      cart_items: {}
      discount: {
        discount: 0,
        ptg: 0
      }
      waiting_cart: false
    }
    mounted: ->
# Acceso a secure local storage
      ls = new SecureLS()
      cart = ls.get 'cart'
      disc = ls.get 'discount'
      @discount = disc if disc
      uid = document.getElementById('uid').dataset.uid
      final_cart = {}
      # Carrito que se llenó sin sesion
      final_cart['joker'] = cart['joker'] if cart['joker']

      # Comprobar si hay usuario
      if uid
        final_cart[uid] = cart[uid]
        query = AxiosRequest "/customers/#{uid}/shopping_carts/check.json"
        query.then ({data: res}) =>
          {cart_items, status, discount} = res

          switch status
            when 'waiting' then @waiting_cart = true
            when 'paid' then final_cart = {}
            when 'active','expired','declined'
              local_cart = _.map final_cart[uid], (elem) => {elem..., date: new Moment(elem.date).format()}
              remote_cart = _.map cart_items, (elem) => {elem..., date: new Moment(elem.date).format()}
              joker_cart = _.map final_cart['joker'], (elem) => {elem..., date: new Moment(elem.date).format()}
              final_cart[uid] = _.unionBy local_cart, remote_cart, (elem) => [elem.date, elem.tour_date_id,
                elem.bookings].join()
              final_cart['joker'] = _.difference joker_cart, final_cart[uid], (elem) =>
                [elem.date, elem.tour_date_id, elem.bookings].join()
          @discount = discount if discount
      @cart_items = final_cart
    computed: {
      total_sumary: ->
        {cart_items} = @
        return 0 if Object.keys(cart_items).length == 0

        uid = document.getElementById('uid').dataset.uid
        joined_cart = []
        if cart_items['joker']
          joined_cart.push(cart_items['joker']...) if cart_items['joker'].length > 0
        if @cart_items[uid]
          joined_cart.push(cart_items[uid]...) if uid
        acum = 0
        for item in joined_cart
          acum += parseInt item.worth * item.bookings
        acum
    }
  }
